import { getAllGroups } from './groupService';
import { getAllJanijim, getJanijById } from './janijService';

export const getAddJanijData = async () => {
    try {
        const janijim = await getAllJanijim(false)
        const groups = await getAllGroups()
        return {
            janijim,
            groups
        }
    } catch (error: any) {
        throw error
    }
}

export const getEditJanijData = async (name: string,reload:boolean=false) => {
    try {
        const groups = await getAllGroups()
        const janijData = await getJanijById(name,reload)
        return {
            groups,
            janijData
        }
    } catch (error: any) {
        throw error
    }
}


