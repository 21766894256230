import axios from 'axios';
import { getCredentials } from "../auth/authUtils";
import { AttendanceDTO } from '../dtos/AttendanceDTO';
import { AttendanceListDTO } from '../dtos/AttendanceListDTO';
import { getDataFromCacheOrFetchSessionStorage } from './cacheServices';

export const getAttendanceByActivity = async (activityId: number): Promise<AttendanceDTO[]> => {
	const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}/attendance/getByActivity/${activityId}`)
	if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
	const data = await response.data
	return data
}


export const getAllAttendanceByDate = async (day: string,month: string, restricted:boolean = false,reload:boolean = false): Promise<AttendanceDTO[]> => {
	const cacheKey = `getAllAttendanceByDate-${day}-${month}`; // Define la clave para el caché de getAllAttendanceByDate
	const fetchFunction = async () => {
		const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=attendances&month=${month}&day=${day}&restricted=${restricted}&${getCredentials()}`);
		if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
		const data = await response.data.content.attendances;
		return data;

	};
	const res = await getDataFromCacheOrFetchSessionStorage(cacheKey, fetchFunction,reload);
	return res;
}

export const getAttendanceByGroup = async (day: string,month: string,group: string, reload:boolean = false): Promise<any[] | null> => {
	const result = await getAllAttendanceByDate(day,month,false,reload);
	const data = result.filter( (attendance:AttendanceDTO)=> attendance.group === group)
	return data
}

export const getAttendanceByNames = async (names: string): Promise<any[] | null> => {
	const day = new Date().getDate();
	const month = new Date().getMonth() +1
	const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=attendances&month=1&maxMonth=${month}&maxDay=${day}&names=${names}&includeSiblings=true&${getCredentials()}`);
	if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
	const data = await response.data.content;
	return data;
}

export const saveAttendance = async (day: string,month: string,attendances:AttendanceDTO[] | AttendanceListDTO[]) => {
	const response:any = await axios({
		method: "post",
		url: `${process.env.REACT_APP_BACKEND_DOMAIN}?action=setAttendances&month=${month}&day=${day}&${getCredentials()}`,
		data: JSON.stringify(attendances),
		});
		if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
		const data = await response.data.content;
	getAllAttendanceByDate(day,month,false,true) //LLAMO AL RELOAD TRUE PARA QUE ACUALICE LOS DATOS 
	return data;
}

