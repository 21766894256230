import axios from "axios";

export const login = async (username: string, password: string) => {
  try {
    const response:any = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=login`, JSON.stringify(({ username, password })));
    if (!response.data.success) throw new Error(response.data.content.error)
    return response.data.content;
  } catch (error) {
    throw error;
  }
};