import { useState } from "react";
import { Button, Input, Label, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

function ArchiveBody(props: any) {
    const [canArchive, setCanArchive] = useState(false)
    const [isArchiving, setIsArchiving] = useState(false)

    const handleChange = (e: any) => {
        e.preventDefault();
    };

    const handleArchiveField = (e: any) => {
        setCanArchive(!canArchive)
    }

    const archiveRequest = async (id: number) => {
        setIsArchiving(true)
		try {
			await props.archive(id)
		} catch (error: any) {
			console.error("err",error);
			props.avisoAlert({
				show: true,
				message: `Error:`,
				extraMessage: error.message,
				status: "danger",
			},5000);
		}
		props.toggle()
        setIsArchiving(false)
        props.refresh()
    }

    return (
        <>
            <ModalHeader toggle={props.toggle} charcode="close">
                {props.title}
            </ModalHeader>
            <ModalBody>
                <p>¿Estás seguro que deseas archivar a "<b>{props.item.name}</b>"?</p>
                <p><span className="text-danger">Al archivar se borra el historial de asistencias y no se podrá recuperar.</span></p>
                <Input
                    id="check"
                    name="check"
                    type="checkbox"
                    onChange={handleArchiveField}
                    autoComplete="off"
                    className="me-2"
                />
                <Label htmlFor="check"> Confirmar</Label>
            </ModalBody>
            <ModalFooter>
                <div className="row col-12">
                    <div className="col-6">
                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={props.toggle}
                                disabled={isArchiving}
                                className="mx-2"
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                    <div className="col-6">
                        <Button
                            color="danger"
                            onClick={() => archiveRequest(props.item.id)}
                            disabled={isArchiving || !canArchive}
                        >
                            {isArchiving ? <div>Archivando... <Spinner animation="border" variant="light" size="sm" /></div> : props.title.split(' ')[0]}
                        </Button>
                    </div>
                </div>

            </ModalFooter>
        </>
    );
}

export default ArchiveBody