export const BAD_REQUEST_ERROR_ENG = "Request failed with status code 400"
export const BAD_REQUEST_ERROR_SPA = "Petición incorrecta a la base de datos"

export const NOT_FOUND_ERROR_ENG = "Network Error"
export const NOT_FOUND_ERROR_SPA = "Error de Conexión"

export const INVALID_CREDENTIALS_ERROR_ENG = "Credenciales inexistentes o inválidas"
export const INVALID_CREDENTIALS_ERROR_SPA = "Error de authenticacion, Intenta cerrar sesion y volver a iniciar" 

export const DEFAULT_ERROR_SPA = "Error desconocido, Comunicate con un desarrollador";

export const getErrorByMessage = (error: any) => {
    if (typeof error === 'string' && error.startsWith('/')) {
        return error.substring(1); // Devuelve el error sin el '/'
    }
    switch (error) {
        case NOT_FOUND_ERROR_ENG:
            return NOT_FOUND_ERROR_SPA
        case BAD_REQUEST_ERROR_ENG:
            return BAD_REQUEST_ERROR_SPA
        case INVALID_CREDENTIALS_ERROR_ENG:
            return INVALID_CREDENTIALS_ERROR_SPA
        default:
            return typeof error === 'string' ? error : DEFAULT_ERROR_SPA
    }
}