import axios from 'axios';
import { getCredentials } from '../auth/authUtils';
import GroupDTO from '../dtos/GroupDTO';
import GroupRequestDTO from '../dtos/GroupRequestDTO';
import { getDataFromCacheOrFetchLocalStorage } from './cacheServices';
import { add, deleteOne, get, switchActive, update } from './crudService';

export const getAllGroups = async (): Promise<any[]> => {
    const cacheKey = "getAllGroups";

    const fetchFunction = async () => {
        const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=groups&${getCredentials()}`);
        if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
        return response.data.content;
    };

    return getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction,false,43800);
}


export const getGroupById = async (id: number): Promise<GroupDTO> => {
    return get("group", id)
}

export const addGroup = async (groupToAdd: GroupRequestDTO) => {
    return add("group", groupToAdd)
}

export const updateGroup = async (id: number, groupToUpdate: GroupRequestDTO) => {
    return update("group", id, groupToUpdate)
}

export const switchActiveGroup = async (id: number, active: boolean) => {
    return switchActive("group", id, active)
}

export const deleteGroup = async (id: number) => {
    return deleteOne("group", id)
}