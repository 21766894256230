import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Input, Modal, Spinner } from 'reactstrap';
import HeaderPage from '../components/UI/Layout/HeaderPage';
import Scroll from '../components/UI/Layout/Scroll';
import DialogBox from '../components/UI/Modals/DialogBox';
import AddJanijBody from '../components/UI/Modals/Janijim/AddJanijBody';
import { useCallbackPrompt } from '../customHooks/useCallbackPrompts';
import { AttendanceListDTO } from '../dtos/AttendanceListDTO';
import { getAttendanceByGroup, saveAttendance } from '../services/attendanceService';
import { compareArrayObjects } from '../utils/dnd/dnd-functions';
import { dateToEsString, formatDateUsToEs } from '../utils/misc/dates';
import { getErrorByMessage } from '../utils/misc/errors';
import preventReload from '../utils/preventReload';
import Loading from './misc/Loading';


export default function AttendanceList(props:any) {
    const history = useNavigate();
    const { date,group } = useParams();
    const [janijimPresents, setJanijimPresents] = useState<AttendanceListDTO[]>([])
    const [changes, setChanges] = useState<AttendanceListDTO[]>([])
    const [loaded, setLoaded] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [isBlocked, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
    const [errorAlert, setErrorAlert] = useState({err:false,msg:""})
    const [error, setError] = useState("")
    const [addModal, setAddModal] = useState(false)
    const [redirect, setRedirect] = useState(true)
    const [tableFilterActives, setTableFilterActives] = useState(false)
    const handleAvisoAlert = props.handleAvisoAlert;
    if(!date || !group){ history('/attendance')}

    const handlePresent = (name: string) => {
        let newPresents: AttendanceListDTO[] = [...janijimPresents]
        const change = newPresents.find((change: AttendanceListDTO) => change.name === name)
        if (change) {
            change.attended = !change.attended
        }
        setJanijimPresents(newPresents)
    }

	useEffect(preventReload, [])

    const getChanges = () =>  compareArrayObjects(changes,janijimPresents);

    
    const handleSaveAttendance = async (redi:boolean=true) => {
        try {
            setRedirect(redi);

            let request: AttendanceListDTO[] = getChanges();

            if (request.length > 0) {
                setRedirect(redi);
                setIsSaving(true)
                const day = date?.split("-")[2]
                const month = date?.split("-")[1]
                if(!day || !month){throw new Error("Error al guardar");}
                await saveAttendance(day,month, request)
                const copiedJanijimPresent = janijimPresents.map((item:any) => ({ ...item }));
                setChanges(copiedJanijimPresent)
                setShowDialog(false)
                setIsSaving(false)

                
            }
        } catch (error:any) {
            setIsSaving(false)
            setErrorAlert({err:true,msg:error.message})
            console.error(error)
        }
        
    }

    const refresh = () => {
            fetchData(true)
    }
    const formatAttendanceLoaded = (arr: any[]): any[] => {
        let Attendance = arr.map((el: any) => ({
          name: el?.name,
          attended: el.attendance[0],
          common:el.common
        }));
      
        return Attendance;
      };
    async function fetchData(reload:boolean = false) {
        setLoaded(false)
        setShowDialog(false)
        setError("")
        try {
        const day = date?.split("-")[2]
        const month = date?.split("-")[1]
        if(!day || !month || !group){return history('/attendance')}
        const attendanceLoaded:any = await getAttendanceByGroup(day, month, group,reload);
        if(!attendanceLoaded || attendanceLoaded.error)throw new Error("Request failed with status code 400")
        const attendance = formatAttendanceLoaded(attendanceLoaded);
        const copiedAttendance = attendance.map((item:any) => ({ ...item }));
        setJanijimPresents(attendance)
        setChanges(copiedAttendance)
        setLoaded(true)
        } catch (error:any) {
            setLoaded(true)
            console.error(error.message);
            setError(error.message)
        }
    }
    const toggleAddModal = () => setAddModal(!addModal);


    
    useEffect(() => {
        if(!isSaving){
            setShowDialog(getChanges().length > 0)
        }
        
    }, [changes,janijimPresents,getChanges]);
    useEffect(() => {
        if(!showDialog && isSaving && redirect ){
             history(-1)
        }
        
    }, [showDialog,isSaving,redirect]);


    useEffect(() => {
        fetchData()
    }, []);
  

    return (
        
        <main>
            
            <HeaderPage title={
                  <div className="row">
                    <button type="button" title='Volver' className="btn btn-danger col-auto" onClick={() => history(-1)}><i className=" fas fa-arrow-left"></i></button>
                    <h5 className='col-8 d-none d-sm-block my-auto'>{dateToEsString(date!)}</h5>
                    <h5 className='col-8 d-block d-sm-none my-auto fw-bold size-1-2'>{formatDateUsToEs(date!)}</h5>
                  </div>
                }>
                          
                    <div className="filters d-flex mx-2 align-items-center justify-content-end">
                        <Button color={!tableFilterActives?'warning':'danger'} disabled={error !== ''} hidden={!loaded} title='habilitar' onClick={() => setTableFilterActives(!tableFilterActives)} type='button' className="">
                        {!tableFilterActives?
                        <><span className="d-none d-md-inline">Ver Todos </span><i className="fa-solid fa-eye"></i></>
                        :<><span className="d-none d-md-inline">Ver solo Activos </span><i className="fa-solid fa-eye-slash"></i></> 
                        }
                        </Button>
                        
                        <Button color='danger' disabled={error !== ''} title='Actualizar' onClick={refresh} hidden={!loaded} type='button' className="mx-3"><i className="fa fa-refresh"></i></Button>
                        <Button onClick={() =>{toggleAddModal();handleSaveAttendance(false);}} color='danger' disabled={error !== ''} hidden={!loaded} type='button'>Agregar Janij Nuevo</Button>
                        <Modal isOpen={addModal} toggle={toggleAddModal} backdrop="static"><AddJanijBody title='Agregar' toggle={toggleAddModal} refresh={refresh} avisoAlert={handleAvisoAlert} attendanceData={{group,date}}/></Modal>
                    </div>            
                          
                      
              </HeaderPage>

            
            <div className="text-center">
                <h4>{group?.split(" ")[1]}</h4>
                <p>{janijimPresents.filter((janij: AttendanceListDTO) => janij.attended).length} Presentes</p>
            </div>
            <div className="justify-content-center table-content p-3 mx-3 mt-4 text-center">
            {errorAlert.err && <Alert color="danger" className="text-center">{getErrorByMessage(errorAlert.msg) }</Alert>}
                
                {loaded ? !error ?
                    <>
                   
                    <div className="justify-content-center text-center">
                    
                        <table className="table table-hover table-responsive">
                            
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre y Apellido</th>
                                    <th scope="col">Presente</th>
                                    
                                </tr>
                            </thead>
                            <tbody className='table-group-divider'>
                                {janijimPresents
                                    .filter((janij: AttendanceListDTO) => tableFilterActives || janij.common )
                                    .map((janij: AttendanceListDTO, index: number) => (
                                        <tr  key={index+1} onClick={() => handlePresent(janij.name)} className={`${!janij.common && tableFilterActives ? "rowDisabled" : ""} align-middle`}>
                                            <td  className="py-3 py-md-0 ">{index + 1}</td>
                                            <td  >{`${janij.name}`}</td>
                                            <td key={index+1}>
                                                <Input
                                                    type='checkbox'
                                                    name='present'
                                                    color='danger'
                                                    onChange={()=>{}}
                                                    disabled={isSaving}
                                                    checked={janij.attended}
                                                />
                                            </td>
                                        </tr>
                                    )
                                    )}
                            </tbody>
                        </table>
                        
                    </div>

                     </>
                     :
                    <div className="text-center">
                        <h3>{getErrorByMessage(error)}</h3>
                    </div>
                    :
                    <Loading />
                }
                {loaded &&
                <div className="sticky-bottom pb-3">
                    <Button
                        className='sticky-bottom'
                        onClick={()=>handleSaveAttendance(true)}
                        color={isSaving ? 'success' : 'secondary'}
                        disabled={isSaving}
                        type='button'
                    >
                        {isSaving ? <>Guardando...<Spinner animation="border" variant="light" size="sm" /></> : 'Guardar Asistencias'}                            
                    </Button>
                </div>
                }
            </div>
          

    

            <DialogBox
                title='Alerta'
                text='¿Estás seguro que deseas salir? Hay cambios sin guardar.'
                showDialog={isBlocked()}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />

            <Scroll showBelow={250} />
        </main >
    );
}
