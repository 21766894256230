import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import logo from '../assets/logo/logo-horizontal.png';
import { setAuthSession } from '../auth/authUtils';
import { login } from '../services/authService';
import { isEmptyOrSpaces } from '../utils/misc/strings';



export default function Login() {
    const [user, setUser] = useState({user:"",password:""})
    const [error, setError] = useState({err:false,msg:""})
    const [showPass, setShowPass] = useState(false)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const navigate = useNavigate();

    const handleChange = (e: any) => {
        setError({err:false,msg:""})
        let { name, value } = e.target
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleClick = async () => {
        setError({err:false,msg:""})
        if(isEmptyOrSpaces(user.user) || isEmptyOrSpaces(user.password)){
            return setError({err:true,msg:"Campos incompletos"});
        }
        
        try {
            setIsRedirecting(true)
            const response = await login(user.user,user.password);
			setAuthSession(response);
			navigate('/');// Redirecciona a la página de Dashboard
			window.location.reload();
            setIsRedirecting(false)
        } catch (loginError:any) {
            setError({err:true,msg:(loginError.message || "Error")});
        }finally{
            setIsRedirecting(false)
        }
        
    }
    
    return (
        <main>
            <div className="row mx-3 justify-content-center align-items-center">
                <div className="logo-container text-center col-12 col-md-6  col-lg-6 mb-4">
                    <img className="img-fluid mb-3" src={logo} alt="logo" />
                </div>
                <div className="justify-content-center text-center col-12 col-md-5 col-lg-4">
                <div className="error-container">
                    {error.err && <Alert color="danger" className="text-center">{error.msg}</Alert>}
                </div>
                <Form>
                    <FormGroup>
                        <Label htmlFor="user">
                            Usuario
                        </Label>
                        <Input
                            id="user"
                            disabled={isRedirecting}
                            name="user"
                            value={user.user}
                            onChange={handleChange}
                            autoComplete="off"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="password">
                            Contraseña
                        </Label>
                        <Input
                            id="password"
                            disabled={isRedirecting}
                            name="password"
                            type={showPass?"text":"password"}
                            value={user.password}
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <Input
                            id="check"
                            disabled={isRedirecting}
                            name="check"
                            type="checkbox"
                            onChange={()=>setShowPass(!showPass)}
                            autoComplete="off"
                        />
                        <Label htmlFor="check" className="mx-2"> Mostrar contraseña</Label>
                    </FormGroup>

                        <Button
                            color={isRedirecting ? "success" : "danger"}
                            disabled={isRedirecting}
                            onClick={handleClick}
                        >
                            {isRedirecting ? <div>Iniciando Sesion... <Spinner animation="border" variant="light" size="sm" /></div> : "Iniciar Sesión"}
                        </Button>
                    
                </Form>
                    
                </div>
            </div>
        </main>
    );
}