import axios from 'axios';
import { getCredentials } from "../auth/authUtils";
import ActivityDTO from '../dtos/ActivityDTO';
import ActivityRequestDTO from '../dtos/ActivityRequestDTO';
import { getDataFromCacheOrFetchLocalStorage } from './cacheServices';
import { add, deleteOne, get, update } from './crudService';

export const getAllActivities = async (sort = true) => {
  const cacheKey = "getAllActivities"; // Define la clave para el caché de getAllActivities
  const fetchFunction = async () => {
    const response:any = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=activities&${getCredentials()}`);
    if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
    let data = await response.data.content;
    if(sort)data =  data.sort((a:any, b:any) => new Date(b).getTime() - new Date(a).getTime())
    return data;
  };

  return getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction);
};


export const getActivityById = async (id: number): Promise<ActivityDTO> => {
    return get("activity", id)
}

export const addActivity = async (activityToAdd: ActivityRequestDTO) => {
    return add("activity", activityToAdd)
}


export const updateActivity = async (id: number, activityToUpdate: ActivityRequestDTO) => {
    return update("activity", id, activityToUpdate)
}

export const deleteActivity = async (id: number) => {
    return deleteOne("activity", id)
}