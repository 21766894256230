import { useBlocker } from "react-router-dom";

export function useCallbackPrompt(when: boolean): (any | (() => void))[] {
	const blocker = useBlocker(({ currentLocation, nextLocation }) =>
		when && currentLocation.pathname !== nextLocation.pathname,
	);

	const isBlocked = () => blocker.state === "blocked";
	const cancelNavigation = blocker.reset;
	const confirmNavigation = blocker.proceed;

	return [isBlocked, confirmNavigation, cancelNavigation]
}