import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, Input, Spinner } from 'reactstrap';
import HeaderPage from '../components/UI/Layout/HeaderPage';
import Scroll from '../components/UI/Layout/Scroll';
import DialogBox from '../components/UI/Modals/DialogBox';
import { useCallbackPrompt } from '../customHooks/useCallbackPrompts';
import { AttendanceDTO } from '../dtos/AttendanceDTO';
import { AttendanceListDTO } from '../dtos/AttendanceListDTO';
import { getAllAttendanceByDate, saveAttendance } from '../services/attendanceService';
import { getAllGroups } from '../services/groupService';
import { compareArrayObjects } from '../utils/dnd/dnd-functions';
import { dateToEsString, formatDateUsToEs } from '../utils/misc/dates';
import { getErrorByMessage } from '../utils/misc/errors';
import { isEmptyOrSpaces, removeAccentsBasic } from '../utils/misc/strings';
import Loading from './misc/Loading';

export default function AttendanceSelectArea() {
    const history = useNavigate();
    let { date } = useParams();

    const [showDialog, setShowDialog] = useState(false)
    const [isBlocked, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)
    const [searchValue, setSearchValue] = useState('')
    const [janijimPresents, setJanijimPresents] = useState<AttendanceDTO[]>([])
    const [changes, setChanges] = useState<AttendanceDTO[]>([])
    const [areas, setAreas] = useState<any>()
    const [loaded, setLoaded] = useState(false)
    const [loadedSearch, setLoadedSearch] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [errorAlert, setErrorAlert] = useState({err:false,msg:""})
    const [error, setError] = useState("")

    const loadAttendance = (date: string, group: string) => {
        history(`/attendance/${date}/${group}`)
    }


    
    const findJanij = (janijInput: string) => {
        if (janijInput === "") return []
      
        return janijimPresents.filter((janij: AttendanceDTO) => {
          const inputWithoutAccents = removeAccentsBasic(janijInput.toLowerCase());
          const nameWithoutAccents = removeAccentsBasic(janij.name.toLowerCase());
          const groupWithoutAccents = removeAccentsBasic(janij.group.toLowerCase());
      
          return (
            nameWithoutAccents.split(" ")[0]?.startsWith(inputWithoutAccents) ||
            nameWithoutAccents.split(" ")[1]?.startsWith(inputWithoutAccents) ||
            `${nameWithoutAccents.split(" ")[0]} ${nameWithoutAccents.split(" ")[1]}`.startsWith(inputWithoutAccents) ||
            groupWithoutAccents.split(" ")[1]?.startsWith(inputWithoutAccents)
          );
        });
      };

    const handleChange = (e?: any) => {
        if (e) {
            setSearchValue(e.target.value)
        } else {
            setSearchValue("")
        }
    }

    const handlePresent = (name: string) => {
      let newPresents: AttendanceDTO[] = [...janijimPresents]
      const change = newPresents.find((change: AttendanceDTO) => change.name === name)
      if (change) {
          change.attended = !change.attended
      }
      setJanijimPresents(newPresents)
  }

   
    const getChanges = () =>  compareArrayObjects(changes,janijimPresents);
    


    const handleSaveAttendance = async () => {
      try {
          let request: AttendanceListDTO[] = getChanges();
          if (request.length > 0) {
              setIsSaving(true)
              const day = date?.split("-")[2]
              const month = date?.split("-")[1]
              if(!day || !month){throw new Error("Error con fechas, comunicate con un desarollador");}
              await saveAttendance(day,month, request)
              setIsSaving(false)
              refresh()
          }
      } catch (error:any) {
          setErrorAlert({err:true,msg:error.message})
          console.error(error)
          setIsSaving(false)
      }
      
  }

    const refresh = async  () => {
        await fetchData()
        setShowDialog(getChanges().length > 0)
    }


    const getAreas = async () => {
        const groups = await getAllGroups();
        const groupedAreas = groups.reduce((acc:any, { name, memberOf }) => {
          (acc[memberOf] ??= []).push(name);
          return acc;
        }, []);
      
        const result = Object.entries(groupedAreas).map(([name, groups]) => ({ name, groups }));
        return result;
      };

    const formatAttendanceLoaded = (arr: any[]): any[] => {
        let Attendance = arr.map((el: any) => ({
          name: el.name,
          group: el.group,
          attended: el.attendance[0],
          common:el.common
        }));
        return Attendance;
      };
    async function fetchData() {
        setLoaded(false)
        setError("");
        try {
            const areas = await getAreas()
            setAreas(areas)
            setLoaded(true)
            const day = date?.split("-")[2]
            const month = date?.split("-")[1]
            if(!day || !month ){return history('/attendance')}
            const attendanceLoaded:any = await getAllAttendanceByDate(day!, month!, false);
            const attendance = formatAttendanceLoaded(attendanceLoaded);
            const copiedAttendance = attendance.map((item:any) => ({ ...item }));
            setJanijimPresents(attendance)
            setChanges(copiedAttendance)
            setLoadedSearch(true)
        } catch (error:any) {
            console.error(error);
            setError(error.message);
            setLoaded(true);
        }

        
    }

    

    useEffect(() => {
        setShowDialog(getChanges().length > 0)
    }, [changes,janijimPresents,getChanges]);

    useEffect(() => {
        fetchData()
    }, []);

    return (
      <main>
          <div className="container">
            {errorAlert.err && <Alert color="danger" className="text-center">{getErrorByMessage(errorAlert.msg) }</Alert>}
            {loaded && !error &&
              <HeaderPage title={
                  <div className="row">
                    <button type="button" title='Volver' className="btn btn-danger col-auto" onClick={() => history(-1)}><i className=" fas fa-arrow-left"></i></button>
                    <h5 className='col-8 d-none d-sm-block my-auto'>{loaded && dateToEsString(date!)}</h5>
                    <h5 className='col-8 d-block d-sm-none my-auto fw-bold size-1-2'>{loaded && formatDateUsToEs(date!)}</h5>
                  </div>
                }>
                              <Input
                              id="janij"
                              name="janij"
                              type="text"
                              autoComplete='off'
                              placeholder='Buscar Janij'
                              onChange={handleChange}
                              value={loadedSearch ? searchValue : "Cargando..."}
                              disabled={!loadedSearch}
                              />
                          
                      
              </HeaderPage>
            }
               
          

          {loaded ? !error ?
              <div className="d-felx align-items-center justify-content-center text-center">
                <div className="size-1-2">
                  <p className="m-0 fst-italic">Presentes Totales</p>
                  <p className="m-0 fs-4">{loadedSearch?janijimPresents.filter(janij=> janij.attended).length:"..."}</p>
                </div>
                  {findJanij(searchValue).length > 0 &&
                  
                        <div>
                          <table className="table table-hover table-responsive mx-auto  mx-3 mb-4 transparent-table ">
                              <thead>
                                  <tr>
                                      <th scope="col">Nombre y Apellido</th>
                                      <th scope="col">Grupo</th>
                                      <th scope="col">Presente</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {findJanij(searchValue).map((janij: AttendanceDTO,index:number) => (
                                      <tr key={index} onClick={() => handlePresent(janij.name)} className={!janij.common ? "rowDisabled" : ""}>
                                          <td>{`${janij.name}`}</td>
                                          <td>{janij.group?.split(" ")[1]}</td>
                                          <td>
                                              <Input
                                                  type='checkbox'
                                                  name='present'
                                                  onChange={() => {}}
                                                  disabled={isSaving}
                                                  checked={janij.attended}
                                              />
                                          </td>
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                          <div className=" sticky-bottom pb-3">
                            <Button
                                className='sticky-bottom'
                                onClick={handleSaveAttendance}
                                color={isSaving ? 'success' : 'secondary'}
                                disabled={isSaving}
                                type='button'
                            >
                                {isSaving ? <>Guardando...<Spinner animation="border" variant="light" size="sm" /></> : 'Guardar Asistencias'}                            
                            </Button>
                          </div>
                          
                        </div>
                  }
                  {!isEmptyOrSpaces(searchValue) && findJanij(searchValue).length === 0 && <p>No hay janijim activos con el nombre "{searchValue}"</p>}
                  {areas && (
  <div className='mt-3'>
    <div className='row'>
      {areas?.map((area: any, i:number) => (
        <div key={i} className='col-md-6 mb-3'>
          <h2>{area.name}</h2>
          <p>{loadedSearch?janijimPresents.filter(janij=>area.groups.includes(janij.group) && janij.attended).length:"..."}  Presentes</p>

          <div className='d-flex justify-content-center align-items-center flex-wrap'>
            {area.groups.map((group: any, index:number) => (
            <div key={index}>

              <Button
                color="danger"
                size='lg'
                className="mx-1 my-0" 
                title={group}
                onClick={() => loadAttendance(date!, group)}
                >
                {group.split(' ')[1]}
              </Button>
                
                <p>{loadedSearch ? janijimPresents.filter(janij => (group === janij.group && janij.attended)).length:"..."} <i className="fa-solid fa-users-line"></i></p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
)}

              </div>
               :
               <div className="text-center">
                   <h3>{getErrorByMessage(error)}</h3>
               </div>
               :
              <Loading />
          }
          </div>
          <DialogBox
              title='Alerta'
              text='¿Estás seguro que deseas salir? Hay cambios sin guardar.'
              showDialog={isBlocked()}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
          />
          <Scroll showBelow={250} />
      </main >
    );
}
