import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	FormGroup,
	Input,
	Modal,
	Spinner
} from "reactstrap";
import HeaderPage from "../components/UI/Layout/HeaderPage";
import Scroll from "../components/UI/Layout/Scroll";
import DeleteBody from "../components/UI/Modals/DeleteBody";
import AddJanijBody from "../components/UI/Modals/Janijim/AddJanijBody";
import ArchiveBody from "../components/UI/Modals/Janijim/ArchiveBody";
import JanijDTO from "../dtos/JanijDTO";
import JanijListDTO from "../dtos/JanijListDTO";
import {
	addJanij,
	archiveJanij,
	deleteJanij,
	getAllJanijim
} from "../services/janijService";
import { getErrorByMessage } from "../utils/misc/errors";
import { filterActive } from "../utils/misc/filter";
import { removeAccentsBasic } from "../utils/misc/strings";
import SkeletonRows from "./misc/SkeletonRows";

export default function Janijim(props: any) {
  const [janijim, setJanijim] = useState<JanijListDTO[]>([]);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [unarchiving, setUnarchiving] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    name: "",
  });
  const navigate = useNavigate();

  const toggleAddModal = () => setAddModal(!addModal);
  const handleAvisoAlert = props.handleAvisoAlert;

  const handleUnarchive = async (nameJanij: any) => {
    try {
      setUnarchiving(true);
      handleAvisoAlert({
        show: true,
        message: `Desarchivando "${nameJanij}"`,
        status: "warning",
        extraMessage: <Spinner animation="border" variant="light" size="sm" />,
      });

      const janijToUnarchive: JanijDTO[] = [
        {
          name: nameJanij,
        },
      ];
      await addJanij(janijToUnarchive);
      setUnarchiving(false);
	  handleAvisoAlert({
        show: true,
        message: `Se desarchivó exitosamente`,
        status: "succes",
      });
      refresh();
    } catch (error: any) {
      console.error("err", error);
      handleAvisoAlert(
        {
          show: true,
          message: `Error:`,
          extraMessage: getErrorByMessage(error.message),
          status: "danger",
        },
        5000
      );
    } finally {
      setUnarchiving(false);
      setTimeout(
        () =>
          handleAvisoAlert({
            show: false,
            message: "",
            status: "warning",
            extraMessage: null,
          }),
        5000
      );
    }
  };

  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const toggleArchiveModal = () => setArchiveModal(!archiveModal);

  const handleDelete = (item: any) => {
    setItemSelected(item);
    toggleDeleteModal();
  };
  const handleArchive = (item: any) => {
    setItemSelected(item);
    toggleArchiveModal();
  };

  const [tableFilter, setTableFilter] = useState("Activos");
  const [searchFilter, setSearchFilter] = useState("");
  const handleTableFilter = (e: any) => {
    if (e.target.name === "viewFilter") {
      setTableFilter(e.target.value);
      setSearchFilter("");
    }
    if (e.target.name === "searchFilter") {
      setSearchFilter(e.target.value);
    }
  };
  const findJanij = (janijInput: string) => {
    if (janijInput === "") return janijim;

    return janijim.filter((janij: JanijListDTO) => {
      const inputWithoutAccents = removeAccentsBasic(janijInput.toLowerCase());
      const nameWithoutAccents = removeAccentsBasic(janij.name.toLowerCase());
      const groupWithoutAccents = removeAccentsBasic(janij.group.toLowerCase());

      return (
        nameWithoutAccents.split(" ")[0]?.startsWith(inputWithoutAccents) ||
        nameWithoutAccents.split(" ")[1]?.startsWith(inputWithoutAccents) ||
        `${nameWithoutAccents.split(" ")[0]} ${
          nameWithoutAccents.split(" ")[1]
        }`.startsWith(inputWithoutAccents) ||
        groupWithoutAccents.split(" ")[1]?.startsWith(inputWithoutAccents)
      );
    });
  };

  const refresh = () => {
    fetchData(true);
  };
  async function fetchData(reload: boolean = false) {
    setError("");
    setSearchFilter("");
    setLoaded(false);
    try {
      const janijim = await getAllJanijim(false, reload);
      setJanijim(janijim);
    } catch (error: any) {
      setError(error.message);
    }
    setLoaded(true);
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main>
      <div className="container container-responsive">
        <HeaderPage title="Janijim">
          <FormGroup className="viewFilter">
            <Input
              id="searchFilter"
              name="searchFilter"
              placeholder="Buscar Janij"
              type="text"
              disabled={!loaded || error !== ''}
              hidden={!loaded}
              width={"20%"}
              autoComplete="off"
              onChange={handleTableFilter}
              value={searchFilter}
            />
          </FormGroup>
          <FormGroup className="viewFilter">
            <Input
              id="viewFilter"
              name="viewFilter"
              type="select"
              onChange={handleTableFilter}
              value={tableFilter}
              disabled={!loaded || error !== ''}
              width={"20%"}
              hidden={!loaded}
            >
              <>
                <option>Activos</option>
                <option>Nuevos</option>
                <option>Inactivos</option>
                <option>Archivados</option>
                <option>Todos</option>
              </>
            </Input>
          </FormGroup>
          <Button
            color="danger"
            disabled={error !== ''}
            title="Actualizar"
            onClick={refresh}
            type="button"
            className="mx-3"
          >
            <i className="fa fa-refresh"></i>
          </Button>
          <Button
            onClick={toggleAddModal}
            disabled={error !== ''}
            className="d-none d-md-block"
            color="danger"
            type="button"
          >
            Agregar Janijim
          </Button>
          <Button
            onClick={toggleAddModal}
            disabled={error !== ''}
            className="d-md-none"
            color="danger"
            type="button"
          >
            <i className="fa-solid fa-plus"></i>
          </Button>
        </HeaderPage>

        <div className="row text-center">
          <div className="table-content  mb-5  ">
            {error === "" ? (
              <table
                className={`table ${loaded && "table-hover"} table-responsive `}
              >
                <thead className="align-middle">
                  <tr>
                    <th scope="col">
                      # <span className="d-none d-md-inline"> | </span>  {" "}
                      {loaded?
                        findJanij(searchFilter).filter((janij: JanijListDTO) =>
                        filterActive(tableFilter, janij)
                        ).length:" ... "
                      }
                    </th>
                    <th scope="col">Nombre y Apellido</th>
                    <th scope="col">Grupo</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {!loaded && <SkeletonRows rows={50} columns={4} />}
                  {loaded &&
                    findJanij(searchFilter)
                      .filter((janij: JanijListDTO) =>
                      filterActive(tableFilter, janij)
                      )
                      .map((janij: JanijListDTO, index: number) => (
                        <tr
                          key={index}
                          className={`font-weight-bold ${
                            !janij.common &&
                            tableFilter === "Todos" &&
                            "rowDisabled"
                          } ${
                            !janij.active &&
                            tableFilter === "Todos" &&
                            "rowFullDisabled"
                          } ${
                            janij.isNew &&
                            tableFilter === "Todos" &&
                            "rowNew"
                          }`}
                        >
                          <td>{index + 1}</td>
                          <td>{`${janij.name}`}</td>
                          <td>{janij.group.split(" ")[1]}</td>
                          <td>
                          <div className=" d-block d-md-none ">
                            <button className="btn btn-secondary dropdown-toggle btn btn-danger" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul className="dropdown-menu">
                                <li><button type="button" title="Detalles" className="btn btn-danger dropdown-item" onClick={() => navigate(`/janijim/${janij.name}`)}><i className=" fas fa-eye"></i> Detalles</button></li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><button type="button" title="Eliminar" className="btn btn-danger dropdown-item" onClick={() => handleDelete({ id: janij.name, name: janij.name })}><i className="fas fa-trash"></i> Eliminar</button></li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><button type="button" title={janij.active ? "Archivar" : "Desarchivar"} disabled={unarchiving} className="btn btn-danger dropdown-item" onClick={() => (janij.active ? handleArchive({ id: janij.name, name: janij.name }) : handleUnarchive(janij.name))}>
                                {janij.active ?<><i className="fas fa-archive"></i> Archivar</> : <><i className="fa-solid fa-boxes-packing"></i>Desarchivar</>}
                                </button></li>
                            </ul>
                            </div>
                            <span className="actions d-none d-md-flex justify-content-center">
                            <button type="button" title="Detalles" className="btn btn-danger" onClick={() => navigate(`/janijim/${janij.name}`)}><i className=" fas fa-eye"></i></button>
                            <button type="button" title="Eliminar" className="btn btn-danger" onClick={() => handleDelete({ id: janij.name, name: janij.name })}><i className="fas fa-trash"></i></button>
                            <button type="button" title={janij.active ? "Archivar" : "Desarchivar"} disabled={unarchiving} className="btn btn-danger" onClick={() => (janij.active ? handleArchive({ id: janij.name, name: janij.name }) : handleUnarchive(janij.name))}>
                                {janij.active ? <i className="fas fa-archive"></i> : <i className="fa-solid fa-boxes-packing"></i>}
                            </button>
                            </span>

                          </td>
                        </tr>
                      ))}
                </tbody>
                <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                  <DeleteBody
                    title="Eliminar Janij"
                    refresh={refresh}
                    toggle={toggleDeleteModal}
                    item={itemSelected}
                    delete={deleteJanij}
					avisoAlert={handleAvisoAlert}
                  />
                </Modal>
                <Modal isOpen={archiveModal} toggle={toggleDeleteModal}>
                  <ArchiveBody
                    title="Archivar Janij"
                    refresh={refresh}
                    toggle={toggleArchiveModal}
                    item={itemSelected}
                    archive={archiveJanij}
					avisoAlert={handleAvisoAlert}
                  />
                </Modal>
              </table>
            ) : (
              <div className="text-center">
                <h3>{getErrorByMessage(error)}</h3>
              </div>
            )}
          </div>
        </div>
        <Scroll showBelow={150} />
      </div>
      <Modal isOpen={addModal} toggle={toggleAddModal} backdrop="static">
        <AddJanijBody
          title="Agregar"
          toggle={toggleAddModal}
          refresh={refresh}
          avisoAlert={handleAvisoAlert}
        />
      </Modal>
    </main>
  );
}
