import React, { useEffect, useState } from "react"

const Scroll = ({ showBelow }: { showBelow: number }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.scrollY > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  }, [showBelow, show])

  const buttonStyles: React.CSSProperties = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    border: "none",
    padding: "10px",
    borderRadius: "50%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    zIndex: "1000",
    transition: "background-color 0.3s, transform 0.3s",
  }

  const arrowStyles: React.CSSProperties = {
    color: "rgb(0, 0, 0)",
    fontSize: "20px",
  }

  return (
    <div>
      {show && (
        <button onClick={handleClick} style={buttonStyles}>
          <i className="fa-solid fa-arrow-up" style={arrowStyles}></i>
        </button>
      )}
    </div>
  )
}

export default Scroll
