import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import SkeletonRows from "../../../pages/misc/SkeletonRows";
import { formatDateMonthDayUsToEs } from "../../../utils/misc/dates";

interface ViewAttendancesProps {
  dates: string[] | null;
  attendances: boolean[] | null;
  isCollapsed?: boolean;
}

export default function ViewAttendances({ dates, attendances,isCollapsed }: ViewAttendancesProps) {

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
          setTimeout(() => {
        if (tableContainerRef.current) {
          tableContainerRef.current.scrollLeft = tableContainerRef.current.scrollWidth;
        }
      },0)
      
    
  }, [dates,isCollapsed]);

  return (
    <div className="mb-3">
      <p className="text-muted" style={{fontSize:"0.8rem",marginBottom:"0"}}>Podés tocar la fecha para abrirla</p>
      <div ref={tableContainerRef} style={{ overflowX: "auto"}} className="secctionViweAttendance">
        <Table bordered style={{ marginBottom: "0px", borderRadius: "60px"}}>
          <thead>
            {!dates ? (
              <SkeletonRows rows={1} columns={10} />
            ) : (
              <tr>
                {dates.map((date, index) => (
                  <td key={index}><Link to={`/attendance/${date}`} className="text-decoration-none text-dark " key={index}>{formatDateMonthDayUsToEs(date)}</Link></td>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {!attendances ? (
              <SkeletonRows rows={1} columns={10} />
            ) : (
              <tr>
                {Array.isArray(attendances) && attendances.map((isPresent, index) => (
                  <td key={index} style={{ backgroundColor: isPresent ? 'green' : 'red' }}></td>
                ))}
              </tr>
            )}
          </tbody>
       
        </Table>
      </div>
    </div>
    
  );
}
