import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import HeaderPage from '../components/UI/Layout/HeaderPage';
import Scroll from '../components/UI/Layout/Scroll';
import { getAllActivities } from '../services/activityService';
import { dateToEsString, formatDateUsToEs } from '../utils/misc/dates';
import { getErrorByMessage } from '../utils/misc/errors';


export default function Attendance() {
    const history = useNavigate();
    const [activities, setActivities] = useState<any[]>([])
    const [loaded, setLoaded] = useState(false)
    const [today, setToday] = useState()
    const [canEditPreviousAttendances, setCanEditPreviousAttendances] = useState(false)
    const [error, setError] = useState("")

    const loadAttendance = (date: string) => {
        history(`/attendance/${date}`)
    }

    const checkToday = () => {
        const todayDate = new Date().toLocaleDateString( 'sv', {timeZone: 'America/Argentina/Buenos_Aires'})
        return activities.find((activity: any) => todayDate === activity)
    }

    const refresh = () => {
        fetchData()
    }

    async function fetchData() {
        try {
            setLoaded(false)
            const activities:any = await getAllActivities()
            if(!activities || activities.error)throw new Error("Request failed with status code 400")
            setActivities(activities)
            setLoaded(true)
        } catch (error:any) {
            setLoaded(true)
            console.error(error.message);
            setError(error.message)
        }
        
    }

    useEffect(() => {
        refresh()
    }, []);

    useEffect(() => {
        setToday(checkToday())
    }, [activities]);

    return (
        <main>
            <div className="container">
                <HeaderPage title="Asistencias">
                    <Button color={!canEditPreviousAttendances?'warning':'danger'} title='habilitar' onClick={() => setCanEditPreviousAttendances(!canEditPreviousAttendances)} type='button' className="">
                        {!canEditPreviousAttendances?
                        <>Habilitar otras fechas <i className="fa-solid fa-check"></i></>
                        :<>Deshabilitar otras fechas <i className="fa-solid fa-x"></i></> 
                        }
                    </Button>
                </HeaderPage>
                
        
                <div className="row text-center">
                    
                    {(loaded && !error) && activities.length > 0 && today &&
                        <div className=" table-content p-2 mb-4 col-md-8 m-auto bg-warning attendanceToday">
                            <h3>Hoy!</h3>
                            <span className='d-flex justify-content-center align-items-center'>
                                <h5 className='d-none d-sm-block'>{dateToEsString(today)}</h5>
                                <h5 className='d-block d-sm-none fw-bold'>{formatDateUsToEs(today)}</h5>
                                <button type="button" title='Cargar Asistencia' className="btn btn-danger mx-3" onClick={() => loadAttendance(today)}><i className=" fas fa-arrow-right"></i></button>
                            </span>
                        </div>
                    }
                    <div className="table-content  mb-5">

                        {loaded ? !error ?
                        <>
                            {
                                activities.length > 0 ?
                                    <table className="table table-hover table-responsive ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Fecha</th>
                                                <th scope="col">Cargar Asistencia</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-group-divider'>
                                            {activities
                                                .filter((activity: any) => Number(activity.split("-")[0]) === new Date().getFullYear() && new Date(activity).getTime() - 1577880000 <= new Date().getTime())
                                                .map((activity: any, index: number) => (
                                                    <tr key={index} className={activity >= new Date().toISOString().split('T')[0] ? "rowDisabled" : ""}>
                                                        <td className="td-attendance d-none d-sm-table-cell">{dateToEsString(activity)}</td>
                                                        <td className="td-attendance d-table-cell d-sm-none">{formatDateUsToEs(activity)}</td>
                                                        <td className="td-attendance">
                                                            <span>
                                                                <button type="button" disabled={!canEditPreviousAttendances} title='Cargar Asistencia' className="btn btn-danger" onClick={() => loadAttendance(activity)}><i className=" fas fa-arrow-right"></i></button>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    : <p>No hay actividades cargadas</p>
                            }
                        </>
                        :
                        <div className="text-center">
                            <h3>{getErrorByMessage(error)}</h3>
                        </div>
                        :
                            <div className="text-center">
                                <Spinner animation="border" className='text-danger my-2' variant="light" />
                            </div>
                        }
                    </div>
                    <Scroll showBelow={250} />
                </div>

            </div>
        </main>
    );
}

