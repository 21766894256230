import axios from "axios";
import { getCredentials } from "../auth/authUtils";
import JanijDTO from "../dtos/JanijDTO";

export const getMessageDebtByName = async (name: string,from:string,to:string,type:string): Promise<JanijDTO> => {
  const response:any = await axios(
    `${process.env.REACT_APP_BACKEND_DOMAIN}?action=message&name=${name}&from=${from}&to=${to}&type=${type}&${getCredentials()}`
  );
  if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
  const data = await response.data.content;
  return data;
};