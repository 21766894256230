import { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarText } from 'reactstrap';
import logo from '../../../assets/logo/logo-solo.png';
import DialogBox from '../Modals/DialogBox';

function HeaderNav(props:any) {
    const { isAuthenticated,user ,signOut } = props;
    const [showPrompt, setShowPrompt] = useState(false)
    const [isRedirecting, setIsRedirecting] = useState(false)

    const handleLogout = () => {
        setShowPrompt(false)
        setIsRedirecting(true)
        signOut()
        window.location.reload()
    }

    const [toggle, setToggle] = useState(false)
    const mediaQuery = window.matchMedia('(max-width: 991px)')
    const handleToggle = () => {
        if (mediaQuery.matches) {
            setToggle(!toggle)
        }
    }

    
    return (
        <header>
            <Navbar
                className='navbar-custom'
                expand
                fixed='top'
            >
                <Link to="/"  className="navbar-textLogo d-flex align-items-center justify-content-center text-center me-3" onClick={() => setToggle(false)}>
                    <img src={logo} className="navbar-logo " alt="logo-navbar" />
                    <p className="d-none d-md-block m-0 ">Juventud Ioná</p>
                    <p className="d-none d-md-none m-0 d-sm-block">Ioná</p>
                </Link>
                
                <Collapse
                    navbar
                >
                    <Nav className="me-auto"  navbar>
                        <NavLink className={(navData) => `dropdown-item d-none d-md-block mx-3 py-1 ${(navData.isActive ? 'active' : '')}`} to="/attendance" onClick={handleToggle}><i className="fa-solid fa-clipboard-check"></i> Asistencia </NavLink>
                        <NavLink className={(navData) => `dropdown-item dropdown-item-movile mx-3 py-1 d-md-none ${(navData.isActive ? 'active' : '')}`} to="/attendance" onClick={handleToggle}><i className="fa-solid fa-clipboard-check size-2"></i></NavLink>
                        <NavLink className={(navData) => `dropdown-item d-none d-md-block mx-3 py-1 ${(navData.isActive ? 'active' : '')}`} to="/janijim" onClick={handleToggle}><i className="fa-solid fa-database "></i> Janijim </NavLink>
                        <NavLink className={(navData) => `dropdown-item dropdown-item-movile mx-3 py-1 d-md-none ${(navData.isActive ? 'active' : '')}`} to="/janijim" onClick={handleToggle}><i className="fa-solid fa-database size-2"></i></NavLink>
                    </Nav>
                    <NavbarText className='d-flex text-align-center justify-content-center align-items-center '>
                        {(isAuthenticated && user?.name) &&
                            <div className="user d-flex text-white mt-md-0 ">
                                <div className='d-flex flex-row justify-content-center align-items-center text-center'>
                                    <span className="mx-3">{user?.name?.split(' ')[0]}</span>
                                    <Link className="dropdown-item user d-none d-md-block mx-3" to="#" onClick={() => setShowPrompt(!showPrompt)}><i className="fa-solid fa-right-from-bracket "></i> {isRedirecting ? "Cerrando Sesión..." : "Cerrar Sesión"}</Link>
                                    <Link className="dropdown-item user d-md-none mx-3 size-2-2" to="#" onClick={() => setShowPrompt(!showPrompt)}>{isRedirecting ? <i className="fa-solid fa-spinner fa-spin-pulse"></i> : <i className="fa-solid fa-right-from-bracket"></i>}</Link>
                                </div>
                            </div>
                        }
                    </NavbarText>
                </Collapse>
            </Navbar>
            <DialogBox
                title='Cerrar Sesión'
                text={`${user?.name?.split(' ')[0]}, ¿Estás seguro que deseas cerrar sesión?`}
                showDialog={showPrompt}
                confirmNavigation={handleLogout}
                cancelNavigation={() => setShowPrompt(false)}
            />
        </header>
    )
}
export default HeaderNav