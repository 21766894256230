export const dateToEsString = (date: string) => {
    return new Date(date.replace(/-/g, '\/')).toLocaleDateString([], { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' }) // eslint-disable-line
}

export const dateToUsString = (date: string) => {
    return new Date(date.replace(/-/g, '\/')).toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' }) // eslint-disable-line
}

export const formatDateUsToEs = (date: string) => {
    const dArr = date.split("-");
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0]
}

export const formatDateEsToUs = (date: string) => {
    const dArr = date.split("-");
    return dArr[0] + "-" + dArr[1] + "-" + dArr[2]
}

export const formatDateMonthDayUsToEs = (date: string) => {
    const dArr = date.split("-");
    return dArr[2] + "/" + dArr[1]
}
export const formatDateMonthUsToEs = (date: string) => {
    const dArr = date.split("-");
    return dArr[1] + "/" + dArr[0]
}
export function formatDateToUsMonthYear(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const formattedDate = `${year}-${month}`;
    return formattedDate;
}
export const formatDateMonthUsToStringMonthEs = (date: string) => {
    const month = date.split("-")[1]
    switch(month){
        case "01":return "Enero";
        case "02":return "Febrero";
        case "03":return "Marzo";
        case "04":return "Abril";
        case "05":return "Mayo";
        case "06":return "Junio";
        case "07":return "Julio";
        case "08":return "Agosto";
        case "09":return "Septiembre";
        case "10":return "Octubre";
        case "11":return "Noviembre";
        case "12":return "Diciembre";
        default : return "";
    }
}

export const formatDateToEsYearMonth = (dateS: string,sConcat :string)=>{
    let date = dateS.split("-") 
    return new Date(Number(date[0]), Number(date[1]) - 1, 1).toLocaleDateString('default', { month: 'long', year: 'numeric' }).replace('de', sConcat)
}