
export default function HeaderPage ({ title, children }:{title:any,children:any}){
  return (
    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row mb-4 px-sm-4  ">
      <h1 className="size-2 fw-bold my-auto mb-md-0 mb-4 col-10 col-md-auto  text-center ">{title}</h1>
      <div className="d-flex align-items-center justify-content-right my-auto">
        {children}
      </div>
    </div>
  );
};