import axios from "axios";
import { getCredentials } from "../auth/authUtils";
import JanijDTO from "../dtos/JanijDTO";
import JanijListDTO from "../dtos/JanijListDTO";
import { getDataFromCacheOrFetchLocalStorage, setCacheLocalStorage } from "./cacheServices";
import { switchActive } from "./crudService";
import { getAllGroups } from "./groupService";

export const getAllJanijim = async (restricted = false,reload = false) => {
  try {
   const cacheKey = "getAllJanijim"; // Define la clave para el caché de getAllJanijim
   const fetchFunction = async () => {
     const response = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=list&restricted=${restricted}&${getCredentials()}`);
     if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
     const data = await response.data.content;
     /*Los Janijim Nuevos hay que cachearlos tambien*/ 
     const newJanijim = data.filter((janij:JanijListDTO) => janij.isNew).map((janij:JanijListDTO) => janij.name);
     const newJanijimNames = newJanijim.join(",","");
     cacheNewJanijimByNames(newJanijimNames)
     getAllGroups()
     return data;
   };
   const res = await getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction,reload);
   return res;
 } catch (error:any) {
    console.error(error);
    throw error
 }
};

export const getJanijById = async (name: string,reload = false): Promise<JanijDTO> => {
  try {
    const cacheKey = `getJanijById-${name}`; // Define la clave para el caché de getAllJanijim
    const fetchFunction = async () => {
      const response = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=data&name=${name}&includeSiblings=true&${getCredentials()}`);
      if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
      const data = await response.data.content;
      return data;
    };
    const res = await getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction,reload);
    return res;
  } catch (error:any) {
     console.error(error);
     throw error
  }

};
export const cacheNewJanijimByNames = async (names: string,reload = false)=> {
  try {
    const response = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=datas&names=${names}&includeSiblings=true&${getCredentials()}`);
    if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
    const data = await response.data.content;

    names.split(",").forEach((name,index) => {
      const cacheKey = `getJanijById-${name}`; // Define la clave para el caché de getAllJanijim
      const value = data[index]
      setCacheLocalStorage(cacheKey,value,240);
    })
    
       
   
  } catch (error:any) {
     console.error(error);
     throw error
  }

};

// export const getJanijById = async (name: string): Promise<JanijDTO> => {
//   const response:any = await axios(
//     `${process.env.REACT_APP_BACKEND_DOMAIN}?action=data&name=${name}&${getCredentials()}`
//   );
//   if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
//   const data = await response.data.content;
//   return data;
// };

export const getFamilyById = async (id: number,reload:boolean = false): Promise<any> => {
  try {
    const cacheKey = `getFamilyById-${id}`; // Define la clave para el caché de getAllJanijim
    const fetchFunction = async () => {
      const response = await axios(`${process.env.REACT_APP_BACKEND_DOMAIN}?action=family&id=${id}&${getCredentials()}`);
      if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
      const data = await response.data.content;
      return data;
    };
    const res = await getDataFromCacheOrFetchLocalStorage(cacheKey, fetchFunction,reload);
    return res;
  } catch (error:any) {
     console.error(error);
     throw error
  }
};

export const addJanij = async (janijToAdd: JanijDTO[]) => {
  try{
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_DOMAIN}?action=add&${getCredentials()}`,
    data: JSON.stringify(janijToAdd),
  });
  if(!response.data.success) {
    throw new Error(response.data.content.error)
  }
  const data = await response.data.content;
  setTimeout(() => getAllJanijim(false,true),1000);
  setTimeout(() => sessionStorage.clear(),1000);
  return data;
} catch (error: any) {
  throw error;
}
};


export const updateJanij = async (janijToUpdate: any) => {
  const response:any = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_DOMAIN}?action=setData&${getCredentials()}`,
    data: JSON.stringify([janijToUpdate]),
  });
  if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
  const data = await response.data.content;
  return data;
};

export const switchActiveJanij = async (id: number, active: boolean) => {
  return switchActive("janij", id, active);
};

export const deleteJanij = async (name: string) => {
  try {
    const response = await axios(
      `${
        process.env.REACT_APP_BACKEND_DOMAIN
      }?action=delete&name=${name?.replace(" ", "%20")}&${
        getCredentials()
      }`
    );
    const data = await response.data;
	if(!data.success) {
	  throw new Error(data.content.error)
	}
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const archiveJanij = async (name: string) => {
  try {
    const response:any = await axios(
      `${
        process.env.REACT_APP_BACKEND_DOMAIN
      }?action=delete&name=${name?.replace(" ", "%20")}&archive=true&${
        getCredentials()
      }`
    );
    if(response.data.success === false){throw new Error(`/${response.data.content.error}`)}
    const data = await response.data;
    return data;
  } catch (error: any) {
    throw error;
  }
};
