/*En este archivo se va a manejar todo el cache de la app*/




export function setSessionData(key:any, value:any) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error al establecer datos en sessionStorage:", error);
  }
}

// Función para obtener datos de sessionStorage
export function getSessionData(key:any) {
  try {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error("Error al obtener datos de sessionStorage:", error);
    return null;
  }
}

// Función para eliminar datos de sessionStorage
export function removeSessionData(key:any) {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error("Error al eliminar datos de sessionStorage:", error);
  }
}

export function setCacheLocalStorage(key:string, content:any, expirationTimeMinutes:number) {
  const now = new Date().getTime(); // Get the current date and time in milliseconds
  const expirationTimeMs = expirationTimeMinutes  * 60 * 1000; // Convert minutes to milliseconds
  const expiration = now + expirationTimeMs; // Calculate the expiration time in milliseconds

  const cacheObject = {
    content,
    expiration,
  };

  // Convert the object to JSON and save it to localStorage
  localStorage.setItem(key, JSON.stringify(cacheObject));
}

export function getCacheLocalStorage(key: string){
  const cacheString: string | null = localStorage.getItem(key);

  if (!cacheString) {
    return null;
  }

  const cacheObject = JSON.parse(cacheString);

  if (!cacheObject || cacheObject.expiration < new Date().getTime()) {
    return null;
  }

  const content = cacheObject.content;
  const minutesRemaining = Math.ceil((cacheObject.expiration - new Date().getTime()) / (60 * 1000));
  
  
  return { content, minutesRemaining };
}






export const getDataFromCacheOrFetchSessionStorage = async (cacheKey:any, fetchFunction:any,reload:boolean = false) => {
  try {
    const data = getSessionData(cacheKey);
    if(reload && data){
      removeSessionData(cacheKey)
    }
    if (!data || reload) {
      const fetchedData = await fetchFunction();
      setSessionData(cacheKey, fetchedData);
      return fetchedData;
    }
    return data;
  } catch (error:any) {
    throw error;
  }
};

export const getDataFromCacheOrFetchLocalStorage = async (cacheKey:any, fetchFunction:any,reload:boolean = false,minutesExpired = 240) => {
  try {
    
    const data = getCacheLocalStorage(cacheKey);
    
    if (data && data.minutesRemaining < 15 ){
        try {
          const fetchedData =  await fetchFunction();
          if(fetchedData.error) throw new Error(fetchedData.error)
          setCacheLocalStorage(cacheKey, fetchedData,minutesExpired);
        } catch (error) {
          throw error;
        }
       
    }

    if (!data || !data.minutesRemaining || reload) {
      const fetchedData = await fetchFunction();
      if(fetchedData.error) throw new Error(fetchedData.error)
      setCacheLocalStorage(cacheKey, fetchedData,minutesExpired);
      return fetchedData;
    }else{
      return data.content;
    }
    
  } catch (error:any) {
    console.error(error);
    throw error;
  }
};



